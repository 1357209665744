var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(!_vm.is_overall_result)?_c('v-row',{staticClass:"align-center justify-space-between"},[(
        _vm.get_active_assessment.assessment_type ===
          _vm.AppAssessmentNames.english_v1 ||
        _vm.get_active_assessment.assessment_type ===
          _vm.AppAssessmentNames.english_v2
      )?[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"d-flex align-center flex-wrap",staticStyle:{"gap":"0.8rem"}},[_c('EnglishAutoCompleteUserSearch',{ref:"autocomplete_user",staticStyle:{"max-width":"20rem"},attrs:{"version":_vm.version,"placeholder":_vm.english_placeholder},on:{"searched_user":_vm.searched_user}}),_c('FiltersBadge',{on:{"filter_change":function (e) {
                _vm.english_placeholder = e;
              }}}),_c('SelectOptions',{attrs:{"option":_vm.EnglishAssessmentFilter,"filters":_vm.pass_fail_filters},on:{"option_change":_vm.update_score_filter}}),_c('SelectOptions',{attrs:{"option":_vm.EnglishAssessmentStatusFilter,"filters":_vm.completed_failed_filter},on:{"option_change":_vm.update_status_filter}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('EnglishSelectedDatePicker',{attrs:{"version":_vm.version},on:{"selected_dates":_vm.selected_dates}})],1)]:_vm._e(),(
        _vm.get_active_assessment.assessment_type ===
          _vm.AppAssessmentNames.annotation_v1 ||
        _vm.get_active_assessment.assessment_type ===
          _vm.AppAssessmentNames.annotation_v2
      )?[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"0.8rem"}},[_c('AnnotationAutoCompleteUserSearch',{ref:"autocomplete_user",staticStyle:{"max-width":"20rem"},attrs:{"version":_vm.version,"placeholder":_vm.annotation_placeholder},on:{"searched_user":_vm.searched_user}}),_c('FiltersBadge',{on:{"filter_change":function (e) {
                _vm.annotation_placeholder = e;
              }}}),_c('SelectOptions',{attrs:{"option":_vm.AnnotationAssessmentFilter,"filters":_vm.pass_fail_filters},on:{"option_change":_vm.update_score_filter}}),_c('SelectOptions',{attrs:{"option":_vm.AnnotationAssessmentStatusFilter,"filters":_vm.completed_failed_filter},on:{"option_change":_vm.update_status_filter}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('AnnotationSelectedDatePicker',{attrs:{"version":_vm.version},on:{"selected_dates":_vm.selected_dates}})],1)]:_vm._e()],2):_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"d-flex align-center flex-wrap",staticStyle:{"gap":"0.8rem"}},[_c('AutoCompleteUserAndEidSearch',{staticStyle:{"max-width":"20rem"},attrs:{"placeholder":_vm.english_placeholder},on:{"searched_user":_vm.searched_user}}),_c('SelectOptions',{attrs:{"option":_vm.EnglishAssessmentFilter,"filters":_vm.pass_fail_filters},on:{"option_change":_vm.update_score_filter}}),_c('AssessmentsFilter')],1)]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('EnglishSelectedDatePicker',{attrs:{"version":_vm.version},on:{"selected_dates":_vm.selected_dates}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }