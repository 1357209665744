










































import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "FiltersBadge",
  data() {
    return {
      filter_overlay: false,
      filter_icon: require("@/assets/icons/bold/filter.svg"),
      filters: [
        { label: this.$t("auth.name"), value: true },
        { label: this.$t("auth.eid-number"), value: false }
      ]
    };
  },
  computed: {},
  props: {},
  methods: {
    toggle_filter_overlay() {
      this.filter_overlay = !this.filter_overlay;
    },
    filter_change(ind: number, filter: TranslateResult) {
      this.$emit("filter_change", filter);
      this.filters = this.filters.map((filter, index) => {
        return { ...filter, value: ind === index };
      });
      this.filter_overlay = false;
    }
  }
});
