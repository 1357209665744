














































import Vue from "vue";
import { get_color_by_name } from "@/utils/colors";
import moment from "moment";
import { mapGetters } from "vuex";
import { APP_ENG_ASSESSMENT_LISTING } from "@/store/modules/recruiter/constants";
import { AppEngAssessmentListing } from "@/store/modules/recruiter/interfaces";

export default Vue.extend({
  name: "EnglishSelectedDatePicker",
  data() {
    return {};
  },
  props: {
    version: {
      type: String,
      default: "v1"
    }
  },
  computed: {
    ...mapGetters("recruiter", {
      app_english_assessment: APP_ENG_ASSESSMENT_LISTING
    }),
    CurrentEngAssessment(): AppEngAssessmentListing {
      return this.app_english_assessment(this.version || "v1");
    }
  },
  methods: {
    clear_dates() {
      this.CurrentEngAssessment.selected_dates = {
        date_filter: [],
        date_range_value: "",
        date_picker_menu: false
      };
      this.$emit("selected_dates", []);
    },
    transform_calender_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children)
        node.setAttribute("stroke", get_color_by_name("primary"));
      return icon;
    },
    async format_date_range(dates: string[]) {
      if (dates.length === 1)
        this.CurrentEngAssessment.selected_dates.date_range_value = moment(
          dates[0]
        ).format("DD MMM YYYY");
      else if (dates.length === 2) {
        this.CurrentEngAssessment.selected_dates.date_range_value = moment(
          dates[0]
        ).format("DD MMM YYYY");
        this.CurrentEngAssessment.selected_dates.date_range_value +=
          "  to  " + moment(dates[1]).format("DD MMM YYYY");
        this.CurrentEngAssessment.selected_dates.date_picker_menu = false;
        this.$emit("selected_dates", dates);
      }
    }
  }
});
